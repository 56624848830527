import React from "react"
import HyperLink from "../../utils/HyperLink"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const IntiativeEventPreview = ({ initiativeAndEvent }) => {
  const title = initiativeAndEvent.name
  const description = initiativeAndEvent.description
  const link = initiativeAndEvent.slug

  const image = getImage(
    initiativeAndEvent.info.flyer.localFile.childImageSharp
  )
  return (
    <section className="group md:even:initiative-and-event-preview-even md:even:px-16  py-10 flex md:odd:flex-row md:even:flex-row-reverse odd:flex-col-reverse even:flex-col-reverse ">
      <div className="md:w-8/12 md:group-odd:pr-8 md:group-even:pl-8">
        <h3 className="md:h3 h4 !text-dd-black-60">{title}</h3>

        <div className=" h-[150px] md:hidden block overflow-hidden">
          <GatsbyImage
            image={image}
            alt={title}
            className=" md:hidden block object-cover object-center mt-8"
          />
        </div>
        <p>{description}</p>

        <p>{link && <HyperLink text="See More Details" url={link} />}</p>
      </div>
      <div className="md:w-4/12 md:block hidden">
        <GatsbyImage image={image} alt={title} className="h-full" />
      </div>
    </section>
  )
}

export default IntiativeEventPreview
