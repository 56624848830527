import React from "react"
import IntiativeEventPreview from "../components/initiatives-and-events/preview"
import PagesLayout from "../components/layout/pages"
import { useStaticQuery, graphql } from "gatsby"

const InitiativesAndEvents = () => {
  const allIntiativesAndEvents = useStaticQuery(graphql`
    query {
      allWpIntiativeandEventCategory {
        edges {
          node {
            name
            info {
              fieldGroupName
              flyer {
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                  }
                }
              }
            }
            description
            slug
          }
        }
      }
    }
  `)

  const showAllIntiativesAndEvents = allIntiativesAndEvents.allWpIntiativeandEventCategory.edges.map(
    (intiativeAndEvent, index) => {
      return (
        <IntiativeEventPreview
          key={index}
          initiativeAndEvent={intiativeAndEvent.node}
        />
      )
    }
  )
  return (
    <PagesLayout title="Initiatives and Events">
      <>
        <section>{showAllIntiativesAndEvents}</section>
      </>
    </PagesLayout>
  )
}

export default InitiativesAndEvents
